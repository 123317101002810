import React, { useState, useEffect } from 'react';
import '../style/HowItWorks.css';

const HowItWorks = () => {
    const [stepsVisible, setStepsVisible] = useState([false, false, false, false, false]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const index = parseInt(entry.target.dataset.index, 10);
                        setStepsVisible((prevVisible) =>
                            prevVisible.map((visible, i) => (i <= index ? true : visible))
                        );
                        entry.target.classList.add('animate__animated', 'animate__fadeInUp');
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        const steps = document.querySelectorAll('.step');
        steps.forEach((el) => observer.observe(el));

        return () => observer.disconnect(); // Clean up the observer when component unmounts
    }, []);

    return (
        <main>
            <section className="how-it-works">
                <h2 className="how-it-works-title">How it Works</h2>
                <div className="steps-and-image">
                    <div className="steps">
                        {[1, 2, 3, 4, 5].map((step, index) => (
                            <div
                                key={index}
                                className={`step ${stepsVisible[index] ? 'in-view' : ''} animate-on-scroll`}
                                data-animate="animate__fadeInUp"
                                data-index={index}
                            >
                                <div className="number-circle">{step}</div>
                                <div className='how-it-works-content'>
                                    <p>
                                        {step === 1 && (
                                            <>
                                                <b>Your belongings:</b> Apply the smart tags to your items for easy identification.
                                            </>
                                        )}
                                        {step === 2 && (
                                            <>
                                                <b>Register Items:</b> Use the app to register and secure your items with detailed information.
                                            </>
                                        )}
                                        {step === 3 && (
                                            <>
                                                <b>Report Lost Items:</b> Quickly report lost items via the app to alert the community and increase recovery chances.
                                            </>
                                        )}
                                        {step === 4 && (
                                            <>
                                                <b>Community Engagement:</b> The platform encourages the community to find and report lost items by scanning the QR codes, promoting responsible actions.
                                            </>
                                        )}
                                        {step === 5 && (
                                            <>
                                                <b>Secure Return Process:</b> Coordinate safe returns through our secure and private system, ensuring your personal information is protected.
                                            </>
                                        )}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="how-it-works-image">
                        <img
                            src="https://res.cloudinary.com/dskwy11us/image/upload/v1724341590/Scanning_Tag_wwd6fm.jpg" // Replace with your actual image URL
                            alt="How it works illustration"
                        />
                    </div>
                </div>
            </section>
        </main>
    );
};

export default HowItWorks;
