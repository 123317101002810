import { API_BASE_CMS_URL } from '../service/apiEndpoints.js';

export const fetchCommunityUrl = async () => {
    try {
        const response = await fetch(`${API_BASE_CMS_URL}/api/community-url?populate=*`);
        const data = await response.json();

        if (data && data.data && data.data.attributes) {
            const { url, image } = data.data.attributes;

            let imageUrl = image?.data?.attributes?.formats?.large?.url;

            // Remove 'large_' from the imageUrl if it exists
            if (imageUrl && imageUrl.includes('large_')) {
                imageUrl = imageUrl.replace('large_', '');
            }

            return { url, imageUrl };
        } else {
            throw new Error('Community data not found in response');
        }
    } catch (error) {
        console.error("Failed to fetch community data:", error);
        throw error;
    }
};
