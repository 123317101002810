import { API_BASE_CMS_URL } from '../service/apiEndpoints.js';

export const fetchVideoUrl = async () => {
    try {
        const response = await fetch(`${API_BASE_CMS_URL}/api/home-video?populate=*`);
        const data = await response.json();

        // Extract the video URL from the API response
        const videoUrl = data.data?.attributes?.video?.data?.attributes?.url;

        return videoUrl;
    } catch (error) {
        console.error("Error fetching video URL:", error);
        return null;
    }
};
