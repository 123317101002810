import React, { useState, useEffect } from 'react';
import '../style/Testimonial.css';
import { getTestimonials } from '../service/testimonialApi.js';

const Testimonial = () => {
    const [testimonials, setTestimonials] = useState([]);
    const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);

    useEffect(() => {
        const fetchTestimonials = async () => {
            const data = await getTestimonials();
            // console.log('Fetched Testimonials:', data); // Log the fetched testimonials
            setTestimonials(data);
        };

        fetchTestimonials();
    }, []);

    useEffect(() => {
        if (testimonials.length > 1) {
            const interval = setInterval(() => {
                setCurrentTestimonialIndex(prevIndex => (prevIndex + 1) % testimonials.length);
            }, 10000); // Change every 10 seconds

            return () => clearInterval(interval);
        }
    }, [testimonials]);

    if (testimonials.length === 0) {
        // console.log('No testimonials available or still loading.');
        return <div>Loading...</div>; // Show a loading message while fetching data
    }

    const testimonial = testimonials[currentTestimonialIndex];
    // console.log('Current Testimonial:', testimonial); // Log the current testimonial being displayed

    return (
        <section className="testimonials">
            <h2 className="animate__animated animate__fadeInDown">Testimonials</h2>
            <div className="testimonial-cards">
                <div className="testimonial-card animate__animated animate__fadeIn">
                    <blockquote>{`"${testimonial.quote}"`}</blockquote>
                    <div className="author">
                        <img
                            src={testimonial.image}
                            alt={testimonial.name}
                            onError={(e) => { e.target.src = 'default-image-url'; }} // Fallback if image fails to load
                        />
                        <div>
                            <strong>{testimonial.name}</strong>
                            <br />
                            {/* <span>{testimonial.role}</span> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Testimonial;