import React, { useEffect, useState } from 'react';
import '../style/Community.css';
import { fetchCommunityUrl } from '../service/community';

const CommunitySection = () => {
    const [communityUrl, setCommunityUrl] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getCommunityData = async () => {
            try {
                const { url, imageUrl } = await fetchCommunityUrl();
                setCommunityUrl(cleanUrl(url));
                setImageUrl(imageUrl); // Set the background image URL
            } catch (err) {
                setError('Failed to fetch community data');
            } finally {
                setLoading(false);
            }
        };

        getCommunityData();
    }, []);

    const cleanUrl = (url) => {
        let cleanedUrl = url ? url.replace(/['"]/g, '').trim() : '';
        if (cleanedUrl && !/^https?:\/\//i.test(cleanedUrl)) {
            cleanedUrl = `https://${cleanedUrl}`;
        }
        return cleanedUrl;
    };

    if (loading) {
        return <p>Loading community data...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <section
            className="community-section"
            style={{
                backgroundImage: `url(${imageUrl})`, // Dynamically set background image
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                // backgroundSize: 'cover'
            }}
        >
            <div className="columns is-vcentered animate-on-scroll" data-animate="animate__fadeInLeft">
                <div className="column is-half image-box">
                    {/* You can also use an img tag if needed */}
                </div>
                <div className="column is-half community-content animate-on-scroll" data-animate="animate__fadeInRight">
                    <h2 className="title is-2">JOIN OUR VIBRANT COMMUNITY</h2>
                    <p className="content">
                        At LFReturnMe, we believe in the power of community. Connect with
                        like-minded individuals, share your experiences, and help each other
                        find lost items. Whether you're here to report a lost item, offer
                        support, or gather success stories, our community is here for you.
                        Together, we can make a difference. Join us today and be part of a
                        caring, proactive network committed to reuniting people with their
                        belongings.
                    </p>
                    {communityUrl && (
                        <a href={communityUrl} target="_blank" rel="noopener noreferrer" className="button is-primary">
                            Join our Community →
                        </a>
                    )}
                </div>
            </div>
        </section>
    );
};

export default CommunitySection;
