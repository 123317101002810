import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchDashboardData } from '../service/tag.js';
import { updateItemStatus, registerNewItem, updateProfile, cancelSubscription, sendEmailToBackend } from '../service/dashboard.js';
import '../style/Dashboard.css';

const DashboardPage = () => {
    const navigate = useNavigate();
    const storedUser = JSON.parse(localStorage.getItem('user'));
    const [user, setUser] = useState(storedUser || null);
    const [currentPage, setCurrentPage] = useState("overview");
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState("");
    const [newItem, setNewItem] = useState({
        item_id: "",
        item_type: "",
        item_name: "",
        tag_id: "",
        item_image: "",
        item_description: "",
        status: "0",
        uuid: storedUser?.uuid || "",
    });
    const [uploadedImageURL, setUploadedImageURL] = useState("");
    const [profileImage, setProfileImage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [items, setItems] = useState([]);
    const [tagsRegistered, setTagsRegistered] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState("");
    const [popupType, setPopupType] = useState("");
    const [selectedSubscription, setSelectedSubscription] = useState({});

    const subscriptionPlans = [
        { label: "Basic", value: "basic", amount: 100 },
        { label: "Standard", value: "standard", amount: 200 },
        { label: "Premium", value: "premium", amount: 300 },
    ];

    useEffect(() => {
        const handlePopState = () => {
            if (!localStorage.getItem('user')) {
                navigate('/signin', { replace: true });
            }
        };
        window.addEventListener('popstate', handlePopState);

        const fetchData = async () => {
            if (!storedUser || !storedUser.uuid) {
                navigate('/signin');
                return;
            }

            try {
                const dashboardData = await fetchDashboardData(storedUser.uuid);
                setUser(dashboardData);

                const itemsData = dashboardData.items || [];
                setItems(itemsData);
                setTagsRegistered(itemsData.length);

                if (!dashboardData.is_verified) {
                    navigate('/otp');
                }

            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            }
        };

        fetchData();
    }, [storedUser, navigate]);

    const triggerPopup = (message, type) => {
        setPopupMessage(message);
        setPopupType(type);
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 3000);
    };

    const handleProfileImageUpload = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith("image/")) {
            setProfileImage(file);
            const reader = new FileReader();
            reader.onload = (e) => setUploadedImageURL(e.target.result);
            reader.readAsDataURL(file);
        } else {
            triggerPopup("Please select a valid image file for your profile picture.", "failure");
        }
    };

    const handleProfileSave = async () => {
        setIsLoading(true);

        try {
            const response = await updateProfile({ ...user, profile_picture: profileImage });

            if (response.status === 200) {
                const updatedData = await fetchDashboardData(user.uuid);
                setUser(updatedData);
                triggerPopup("Profile updated successfully!", "success");
            } else {
                triggerPopup(`Error updating profile: Status code ${response.status}`, "failure");
            }
        } catch (error) {
            triggerPopup(`Error updating profile: ${error.message}`, "failure");
        } finally {
            setIsLoading(false);
        }
    };

    const handleRegisterNewItem = async () => {
        if (
            !newItem.item_name.trim() ||
            !newItem.item_type.trim() ||
            !newItem.tag_id.trim() ||
            !newItem.item_description.trim() ||
            !newItem.item_image
        ) {
            triggerPopup("All item fields are required.", "failure");
            return;
        }

        setIsLoading(true);

        try {
            const response = await registerNewItem(newItem);

            if (response.status === 200) {
                const updatedData = await fetchDashboardData(user.uuid);
                setUser(updatedData);
                setItems(updatedData.items || []);
                setTagsRegistered(Object.keys(updatedData.items || {}).length);
                triggerPopup("Item registered successfully!", "success");
                setShowModal(false);
            } else {
                triggerPopup(`Error registering new item`, "failure");
            }
        } catch (error) {
            triggerPopup(`Error registering new item: ${error.message}`, "failure");
        } finally {
            setIsLoading(false);
        }
    };

    const handlePayment = (item) => {
        if (!selectedSubscription[item.tag_id]) {
            triggerPopup("Please select a subscription plan.", "failure");
            return;
        }

        const selectedPlan = subscriptionPlans.find(plan => plan.value === selectedSubscription[item.tag_id]);

        navigate('/payment', {
            state: {
                tag: {
                    ...item,
                    email_address: user.email_address,
                },
                selectedPlan
            }
        });
    };

    const handleCancelSubscription = async (tag) => {
        try {
            const success = await cancelSubscription(tag.tag_id);

            if (success) {
                const updatedItems = items.map(item =>
                    item.tag_id === tag.tag_id ? { ...item, subscription_status: 'inactive' } : item
                );
                setItems(updatedItems);
                triggerPopup(`Subscription for tag ${tag.tag_id} cancelled successfully.`, "success");
            }
        } catch (error) {
            triggerPopup(`Failed to cancel subscription for tag ${tag.tag_id}.`, "failure");
        }
    };

    const filteredItems = () => {
        const itemsArray = Array.isArray(items) ? items : Object.values(items || []);

        if (searchQuery.trim() === "") {
            return itemsArray;
        }

        return itemsArray.filter((item) =>
            item.item_name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };

    const handleUpdateCard = async () => {
        if (!user || !user.email_address) {
            triggerPopup("No email found for the user", "error");
            return;
        }

        setIsLoading(true);

        try {
            await sendEmailToBackend(user.email_address);
            triggerPopup("Update card email sent successfully!", "success");
        } catch (error) {
            triggerPopup(`Error sending email: ${error.message}`, "failure");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="dashboard-container">
            <header className="navbar">
                <p className="navbar-label">LFReturnMe</p>
                <nav>
                    <button onClick={() => setCurrentPage('overview')}>Overview</button>
                    <button onClick={() => setCurrentPage('profile')}>Profile</button>
                    <button onClick={() => setCurrentPage('Subscription')}>Subscription</button>
                    <button onClick={() => {
                        localStorage.removeItem('user');
                        window.location.href = '/signin';
                    }}>Logout</button>
                </nav>
            </header>

            {currentPage === "overview" && (
                <div className="overview">
                    <div className="tags-registered">
                        <p>Tags Registered: {tagsRegistered}</p>
                    </div>
                    <input
                        type="text"
                        placeholder="Search items..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="input"
                    />
                    <br />
                    <button className="button" onClick={() => { setShowModal(true); setModalType('register'); }}>Register New Item</button>

                    <div className="table-container">
                        <h2>Registered Items and Subscriptions</h2>
                        <table className="items-table">
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Tag</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                    <th>Subscription Plan</th>
                                    <th>Action</th>
                                    <th>Subscription Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredItems().map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            {item.item_image && (
                                                <img src={item.item_image} alt="Item" className="item-image" />
                                            )}
                                        </td>
                                        <td>{item.item_name}</td>
                                        <td>{item.item_type}</td>
                                        <td>{item.tag_id}</td>
                                        <td>{item.item_description}</td>
                                        <td>
                                            <select
                                                value={item.status}
                                                onChange={(e) => updateItemStatus(item.tag_id, e.target.value)}
                                                className="input"
                                            >
                                                <option value="0">Active</option>
                                                <option value="1">Lost</option>
                                                <option value="2">Found</option>
                                                <option value="3">Archived</option>
                                            </select>
                                        </td>
                                        <td>
                                            {item.tier === 'basic' || item.tier === 'standard' || item.tier === 'premium' ? (
                                                <span>{item.tier.charAt(0).toUpperCase() + item.tier.slice(1)}</span>
                                            ) : (
                                                <select
                                                    onChange={(e) => setSelectedSubscription(prev => ({
                                                        ...prev,
                                                        [item.tag_id]: e.target.value
                                                    }))}
                                                    value={selectedSubscription[item.tag_id] || ""}
                                                    className="input"
                                                >
                                                    <option value="">Select Plan</option>
                                                    {subscriptionPlans.map((plan) => (
                                                        <option key={plan.value} value={plan.value}>
                                                            {plan.label} - {plan.amount} NGN
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        </td>
                                        <td>
                                            {item.subscription_status === 'active' ? (
                                                <button
                                                    className="button"
                                                    onClick={() => handleCancelSubscription(item)}
                                                >
                                                    Cancel Subscription
                                                </button>
                                            ) : (
                                                <button
                                                    className="button"
                                                    onClick={() => handlePayment(item)}
                                                >
                                                    Pay for Tag
                                                </button>
                                            )}
                                        </td>
                                        <td>
                                            {item.subscription_status
                                                ? (item.subscription_status === 'active' ? 'Active' : 'Inactive')
                                                : 'Waiting...'}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}

            {currentPage === 'Subscription' && (
                <div className="container">
                    <br />
                    <br />
                    <button
                        className="cta-button"
                        onClick={handleUpdateCard}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Loading...' : 'Update Card'}
                    </button>
                </div>
            )}

            {currentPage === "profile" && user && (
                <div className="profile">
                    <br />
                    <br />
                    <img src={uploadedImageURL || user.profile_picture} alt="Profile" className="profile-picture" />
                    <div className="profile-details">
                        <br />
                        <div>
                            <label>Name:</label>
                            <input
                                disabled
                                type="text"
                                value={user.full_name}
                                className="input"
                            />
                        </div>
                        <div>
                            <label>Email:</label>
                            <input
                                disabled
                                type="email"
                                value={user.email_address}
                                className="input"
                            />
                        </div>
                        <div>
                            <label>Phone:</label>
                            <input
                                disabled
                                type="text"
                                value={user.phone_number}
                                className="input"
                            />
                        </div>
                        <div>
                            <label>Address:</label>
                            <input
                                disabled
                                type="text"
                                value={user.address}
                                className="input"
                            />
                        </div>
                        <div>
                            <label>Profile Picture:</label>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (file) {
                                        setUser(prev => ({ ...prev, profile_picture: file }));
                                        const reader = new FileReader();
                                        reader.onload = () => setUploadedImageURL(reader.result);
                                        reader.readAsDataURL(file);
                                    }
                                }}
                                className="input"
                            />
                        </div>
                        <button className="button" onClick={handleProfileSave}>
                            {isLoading ? 'Saving...' : 'Save Changes'}
                        </button>
                    </div>
                </div>
            )}

            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>{modalType === "register" ? "Register New Item" : "Update Profile"}</h2>
                        {modalType === "register" && (
                            <div>
                                <input
                                    type="text"
                                    placeholder="Item ID"
                                    onChange={(e) => setNewItem(prev => ({ ...prev, item_id: e.target.value }))}
                                    className="input"
                                />
                                <input
                                    type="text"
                                    placeholder="Item Name"
                                    onChange={(e) => setNewItem(prev => ({ ...prev, item_name: e.target.value }))}
                                    className="input"
                                />
                                <input
                                    type="text"
                                    placeholder="Item Type"
                                    onChange={(e) => setNewItem(prev => ({ ...prev, item_type: e.target.value }))}
                                    className="input"
                                />
                                <input
                                    type="text"
                                    placeholder="Tag ID"
                                    onChange={(e) => setNewItem(prev => ({ ...prev, tag_id: e.target.value }))}
                                    className="input"
                                />
                                <input
                                    type="text"
                                    placeholder="Item Description"
                                    onChange={(e) => setNewItem(prev => ({ ...prev, item_description: e.target.value }))}
                                    className="input"
                                />
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        if (file) {
                                            setNewItem(prev => ({ ...prev, item_image: file }));
                                            const reader = new FileReader();
                                            reader.onload = () => setUploadedImageURL(reader.result);
                                            reader.readAsDataURL(file);
                                        }
                                    }}
                                    className="input"
                                />
                                <br />
                                <button className="button" onClick={handleRegisterNewItem}>
                                    {isLoading ? 'Registering...' : 'Submit'}
                                </button>
                            </div>
                        )}
                        <br />
                        <button className="button-close" onClick={() => setShowModal(false)}>Close</button>
                    </div>
                </div>
            )}

            {showPopup && (
                <div className={`popup ${popupType}`}>
                    <p>{popupMessage}</p>
                </div>
            )}
        </div>
    );
};

export default DashboardPage;
