import { API_SIGNUP_URL } from "./apiEndpoints";

export const registerUser = async (formData) => {
    try {
        const response = await fetch(API_SIGNUP_URL, {
            method: "POST",
            body: formData,
            headers: {
                // Ensure you're sending the right content type for form data
                "Accept": "application/json",
                // Remove "Content-Type" for formData because fetch will automatically set the boundary when sending a multipart form
            },
        });

        // Check if the response is OK (status code 200-299)
        if (!response.ok) {

            const errorData = await response.json();
            const errorMessage = errorData.message || "Failed to submit the form";

            throw new Error(errorMessage); // Throw the error with the backend's message
        }

        const result = await response.json();

        return result; // Return the successful response data
    } catch (error) {

        throw error; // Rethrow error for handling in the calling component
    }
};
