import { API_BASE_CMS_URL } from './apiEndpoints';

export const fetchAboutData = async () => {
    try {
        const response = await fetch(`${API_BASE_CMS_URL}/api/ceo-img?populate=*`);
        const data = await response.json();

        // Debugging: Log the entire response
        // console.log("API response:", data);

        if (data && data.data) {
            const aboutItem = data.data;

            // Extract the image URL from the response
            const imageUrl = aboutItem.attributes?.image?.data?.attributes?.formats?.large?.url || '';
            const altText = aboutItem.attributes?.image?.data?.attributes?.alternativeText || '';

            // Debugging: Log the extracted image URL and alt text
            // console.log("Extracted Image URL:", imageUrl);
            // console.log("Extracted Alt Text:", altText);

            return {
                name: aboutItem.attributes?.name || '',
                description: aboutItem.attributes?.description || '',
                imageUrl,
                altText,
                author: aboutItem.attributes?.name || '',
            };
        }

        return null;
    } catch (error) {
        console.error('Error fetching about data:', error);
        return null;
    }
};
