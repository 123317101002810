import React, { useEffect, useState } from 'react';
import '../style/About.css';
import { fetchAboutData } from '../service/aboutimg'; // Import the service

const About = () => {
    const [aboutData, setAboutData] = useState(null);

    useEffect(() => {
        const getAboutData = async () => {
            const data = await fetchAboutData();

            // Debugging: Log the fetched about data


            setAboutData(data);
        };

        getAboutData(); // Fetch data on component mount
    }, []);

    return (
        <section className="about">
            <div className="container">
                <h1 className="about-title">About LfreturnMe</h1>

                <div className="about-content">
                    <div className="about-image">
                        {aboutData?.imageUrl ? (
                            <img
                                src={aboutData.imageUrl}
                                alt={aboutData.altText || 'About image'}
                            />
                        ) : (
                            <p>No image available</p>
                        )}
                        <p className="image-label">{aboutData?.author || 'Mrs. Adeline Clinton-Agbor'}</p>
                    </div>
                    <div className="about-text">
                        <p>
                            {aboutData?.description || `Welcome to LFReturnMe, your trusted partner in recovering lost items swiftly and securely. Our mission is to reunite you with your belongings through innovative technology and unparalleled customer service. With a commitment to professionalism and reliability, we ensure that every lost item finds its way back to its owner. Join our community and experience peace of mind knowing that we're here to help, 24/7. At LFReturnMe, we turn lost into found.`}
                        </p>
                        {/* <p>
                            <strong>Our Values:</strong>
                        </p>
                        <ul>
                            <li>Trust: Dependable and secure services</li>
                            <li>Innovation: Cutting-edge solutions</li>
                            <li>Community: Supportive and collaborative environment</li>
                        </ul> */}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
