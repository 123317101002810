import { API_BASE_CMS_URL } from '../service/apiEndpoints.js';
import axios from 'axios';

const API_URL = `${API_BASE_CMS_URL}/api/testimonies?populate=*`;

export const getTestimonials = async () => {
    try {
        const response = await axios.get(API_URL);
        // console.log('API Response:', response.data); // Log the entire API response
        return response.data.data.map((item) => {
            const formats = item.attributes.image?.data?.attributes?.formats;
            const imageUrl = formats?.medium?.url || formats?.small?.url || formats?.thumbnail?.url || 'default-image-url'; // Check multiple formats
            return {
                id: item.id,
                name: item.attributes.name.trim(),
                quote: item.attributes.desc,
                image: imageUrl,
                role: 'Role', // Replace with the correct role if available
            };
        });
    } catch (error) {
        // console.error('Error fetching testimonials:', error);
        return [];
    }
};